import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

class Hero extends Component {
  static propTypes = {
    i18n: PropTypes.object.isRequired
  }

  render() {
    const { i18n } = this.props;

    return (
      <div className="container">
        <div className="row">
          <div className="col hero">
            <h1>
              Uw jaarlijkse intervisiepunten<br />
              in twee avonden behalen.
            </h1>
            <p>
              Advocaten dienen in 2024 acht intervisiepunten te behalen. Schrijf u nu in voor een geheel verzorgde sessie,
              waarmee u in één sessie overdag of in de avond de eerste vier punten voor dit jaar behaalt.
            </p>

            <Link to="/agenda" className="btn btn-primary">Bekijk Agenda</Link>

            <div className="socials">
              <a href="https://twitter.com/legal__nl" target="_blank" title="Twitter">
                <i className="socicon-twitter" />
              </a>

              <a href="https://www.linkedin.com/company/legalnl" target="_blank" title="LinkedIn">
                <i className="socicon-linkedin" />
              </a>

              <a href="https://www.facebook.com/legal.nl" target="_blank" title="Facebook">
                <i className="socicon-facebook" />
              </a>

              <a href="https://www.instagram.com/legal.nl" target="_blank" title="Instagram">
                <i className="socicon-instagram" />
              </a>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <h2>Intervisie voor advocaten</h2>
            <p>Bij intervisie bespreekt u, onder leiding van een professionele gespreksleider, als advocaten onder elkaar niet-juridische vraagstukken. Het doel van intervisie is de verdere ontwikkeling van de professionele houding en werkwijze van de deelnemers, door in een veilige omgeving van elkaar te leren. De Nederlandse Orde van Advocaten heeft met ingang van 1 maart 2020 binnen haar kwaliteitstoets voor alle advocaten acht uren aan intervisie verplicht gesteld.</p>

            <Link to="/agenda" className="btn btn-primary">Bekijk Agenda</Link>
          </div>
        </div>
      </div>
    );
  }
}

// connect redux
const mapStateToProps = (state) => ({
  i18n: state.app.i18n
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(Hero);
