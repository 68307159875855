import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { fetchGET } from '@hydra/atom/lib';

import { Loader } from '@hydra/atom/components';

import { appSettings } from 'settings';

import greenCircle from 'images/green-circle.svg';
import redCircle from 'images/red-circle.svg';

class Sessions extends Component {
  static propTypes = {
    full: PropTypes.bool.isRequired,
    i18n: PropTypes.object.isRequired
  }

  state = {
    sessions: [],
    loading: true
  }

  componentDidMount() {
    const { full } = this.props;

    fetchGET(`${appSettings.apiBaseUrl}/sessions`, {
      stateContext: this,
      onSuccess: (json) => {
        if (json.success) {
          const sessions = full ? json.data : json.data.slice(0, 6);
          this.setState({ sessions });
        }
        else {
          this.setState({ error: json.message });
        }
      },
      onError: (error) => {
        this.setState({ error });
      }
    });
  }

  render() {
    const { full, i18n } = this.props;
    const { loading, error, sessions } = this.state;

    if (loading) {
      return <Loader />;
    }

    if (error) {
      return <h3>{error}</h3>;
    }

    return (
      <div className="table-container">
        <table className="table">
          <thead>
            <tr>
              <th width="150">Datum</th>
              <th width="150">Tijd</th>
              <th width="150">Locatie</th>
              <th width="200">Plaatsen</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {
              sessions.map(session => (
                <tr>
                  <td>{session.date}</td>
                  <td>{session.start}:00 - {session.end}:00</td>
                  <td>{session.location.name}, {session.location.city}</td>
                  <td>
                    {
                      session.has_capacity ? (
                        <>
                          <img src={greenCircle} className="mr-2" alt="" />
                          Beschikbaar
                        </>
                      ) : (
                        <>
                          <img src={redCircle} className="mr-2" alt="" />
                          Niet meer beschikbaar
                        </>
                      )
                    }
                  </td>
                  <td className="text-right">
                    {
                      session.has_capacity ? (
                        <Link to={{ pathname: '/register', state: { date: session.date } }} className="btn btn-primary">
                          Aanmelden
                        </Link>
                      ) : (
                        <Link className="btn btn-primary disabled">
                          Aanmelden
                        </Link>
                      )
                    }
                  </td>
                </tr>
              ))
            }
          </tbody>
        </table>

        {!full && (
          <div className="action">
            <Link to="/agenda" className="btn btn-secondary">Bekijk de volledige agenda → </Link>
          </div>
        )}
      </div>
    );
  }
}

// connect redux
const mapStateToProps = (state) => ({
  i18n: state.app.i18n
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(Sessions);
