import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { fetchGET, fetchPOST } from '@hydra/atom/lib';
import { Select, Button, Form, Input, Checkbox, Loader } from '@hydra/atom/components';

import { appSettings } from 'settings';

class Register extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    i18n: PropTypes.object.isRequired
  }

  defaultState = {
    date: this.props.location.state.date,
    companyName: '',
    firstName: '',
    lastName: '',
    zipCode: '',
    houseNumber: '',
    street: '',
    city: '',
    phone: '',
    email: '',
    dietery: '',
    practice: false,
    wantsSubscription: false,
    hasSubscription: false,
    legal: false,
    loading: false
  };

  state = {
    ...this.defaultState,
    sessions: [],
    loading: true
  };

  componentDidMount() {
    fetchGET(`${appSettings.apiBaseUrl}/sessions`, {
      stateContext: this,
      onSuccess: (json) => {
        if (json.success) {
          this.setState({ sessions: json.data.filter(s => s.has_capacity) });
        }
        else {
          this.setState({ error: json.message });
        }
      },
      onError: (error) => {
        this.setState({ error });
      }
    });
  }

  toggleLegal = () => {
    const { legal } = this.state;

    this.setState({ legal: !legal });
  }

  togglePractice = () => {
    const { practice } = this.state;

    this.setState({ practice: !practice });
  }

  toggleWantsSubscription = () => {
    const { wantsSubscription } = this.state;

    this.setState({ wantsSubscription: !wantsSubscription });
  }

  toggleHasSubscription = () => {
    const { hasSubscription } = this.state;

    this.setState({ hasSubscription: !hasSubscription });
  }

  save = () => {
    const { legal, date, companyName, firstName, lastName, zipCode, houseNumber, street, city, phone, email, dietery, practice, hasSubscription, wantsSubscription } = this.state;

    if (!legal) {
      toast.error('U dient eerst akkoord te gaan met de algemene voorwaarden en privacy verklaring');
      return;
    }

    const vars = {
      date,
      company_name: companyName,
      first_name: firstName,
      last_name: lastName,
      zip_code: zipCode,
      house_number: houseNumber,
      street,
      city,
      phone,
      email,
      dietery,
      practice,
      has_subscription: hasSubscription,
      wants_subscription: wantsSubscription
    };

    this.setState({ loading: true });

    fetchPOST(`${appSettings.apiBaseUrl}/register`, vars, {
      onSuccess: (json) => {
        if (json.success) {
          this.setState({ ...this.defaultState });
        }

        toast.success('Uw aanvraag is successvol ontvangen, u hoort spoedig van ons.');
      },
      onError: (error) => {
        toast.error(error);
      },
      onCompleted: () => {
        this.setState({ loading: false });
      }
    });
  }

  render() {
    const { i18n } = this.props;
    const { date, companyName, firstName, lastName, zipCode, houseNumber, street, city, phone, email, dietery, practice, hasSubscription, wantsSubscription } = this.state;
    const { legal, loading, error, sessions } = this.state;

    if (loading) {
      return <Loader />;
    }

    if (error) {
      return <h3>{error}</h3>;
    }

    const mappedSessions = sessions.map(s => ({
      label: `${s.date}, tijd - ${s.start}:00 - ${s.end}:00`,
      value: s.date
    }));

    return (
      <div className="container content space">
        <div className="row">
          <div className="col">
            <h2>Aanmelden voor intervisie</h2>
          </div>
        </div>

        <div className="row mt-2">
          <div className="col">
            <Form onSubmit={() => this.save()}>
              <div className="row">
                <div className="col-2">
                  <label>Tijdstip</label>
                </div>

                <div className="col-5">
                  <Select
                    clearable
                    value={date}
                    options={mappedSessions}
                    onChange={r => this.setState({ date: r })} />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-2">
                  <label>Bedrijfsnaam</label>
                </div>

                <div className="col-5">
                  <Input
                    name="companyName"
                    type="text"
                    value={companyName}
                    autoFocus
                    onChange={(r) => this.setState({ companyName: r })}
                    rules="required|max:30"
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-2">
                  <label>Voornaam</label>
                </div>

                <div className="col-5">
                  <Input
                    name="firstName"
                    type="text"
                    value={firstName}
                    onChange={(r) => this.setState({ firstName: r })}
                    rules="required|alpha_space|max:30"
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-2">
                  <label>Achternaam</label>
                </div>

                <div className="col-5">
                  <Input
                    name="lastName"
                    type="text"
                    value={lastName}
                    onChange={(r) => this.setState({ lastName: r })}
                    rules="required|alpha_space|max:30"
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-7">
                  <hr />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-2">
                  <label>Postcode</label>
                </div>

                <div className="col-5">
                  <Input
                    name="zipCode"
                    type="text"
                    value={zipCode}
                    onChange={(r) => this.setState({ zipCode: r })}
                    rules="required|max:6"
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-2">
                  <label>Huisnummer</label>
                </div>

                <div className="col-5">
                  <Input
                    name="houseNumber"
                    type="text"
                    value={houseNumber}
                    onChange={(r) => this.setState({ houseNumber: r })}
                    rules="required|max:6"
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-2">
                  <label>Straat</label>
                </div>

                <div className="col-5">
                  <Input
                    name="street"
                    type="text"
                    value={street}
                    onChange={(r) => this.setState({ street: r })}
                    rules="required|max:50"
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-2">
                  <label>Plaatsnaam</label>
                </div>

                <div className="col-5">
                  <Input
                    name="phone"
                    type="text"
                    value={city}
                    onChange={(r) => this.setState({ city: r })}
                    rules="required|max:50"
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-7">
                  <hr />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-2">
                  <label>Telefoonnummer</label>
                </div>

                <div className="col-5">
                  <Input
                    name="phone"
                    type="text"
                    value={phone}
                    onChange={(r) => this.setState({ phone: r })}
                    rules="required|phone|max:15"
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-2">
                  <label>E-mail</label>
                </div>

                <div className="col-5">
                  <Input
                    name="email"
                    type="text"
                    value={email}
                    onChange={(r) => this.setState({ email: r })}
                    rules="required|email|max:50"
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-2">
                  <label>Dieetwensen</label>
                </div>

                <div className="col-5">
                  <Input
                    name="dietery"
                    type="text"
                    value={dietery}
                    onChange={(r) => this.setState({ dietery: r })}
                    rules="required|max:250"
                  />
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-2">
                </div>

                <div className="col-6">
                  <Checkbox value={practice} onChange={this.togglePractice} />

                  <span role="button" tabIndex={0} onClick={this.togglePractice}>
                    Toevoegingspraktijk
                  </span>
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-2">
                </div>

                <div className="col-6">
                  <Checkbox value={wantsSubscription} onChange={this.toggleWantsSubscription} />

                  <span role="button" tabIndex={0} onClick={this.toggleWantsSubscription}>
                    Ik wil een abonnement (2 sessies)
                  </span>
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-2">
                </div>

                <div className="col-6">
                  <Checkbox value={hasSubscription} onChange={this.toggleHasSubscription} />

                  <span role="button" tabIndex={0} onClick={this.toggleHasSubscription}>
                    Ik heb al een abonnement
                  </span>
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-2">
                </div>
                <div className="col-6">
                  <Checkbox value={legal} onChange={this.toggleLegal} />

                  <span role="button" tabIndex={0} onClick={this.toggleLegal}>
                    Ik ga akkoord met de <Link to="/algemene-voorwaarden">algemene voorwaarden</Link> en de <Link to="/privacy">privacyverklaring</Link> van intervisie.amsterdam.
                  </span>
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-2">
                </div>
                <div className="col-6">
                  <Button loading={loading} type="submit">
                    Verstuur
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

// connect redux
const mapStateToProps = (state) => ({
  i18n: state.app.i18n
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);
