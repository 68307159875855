import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

class Privacy extends Component {
  static propTypes = {
    i18n: PropTypes.object.isRequired
  }

  render() {
    const { i18n } = this.props;

    return (
      <div className="container content space">
        <div className="row">
          <div className="col">
            <h2>Privacy</h2>
            <p>intervisie.amsterdam is een website van Legal & Fiscal BV.</p>
            <p>intervisie.amsterdam hecht belang aan de bescherming van uw privacy. De via deze website van u verkregen persoonsgegevens worden verwerkt in overeenstemming met de geldende privacyregelgeving en worden alleen gebruikt op de wijze zoals beschreven in deze verklaring.</p>

            <h6>DOELEINDEN VAN DE VERWERKING VAN UW PERSOONSGEGEVENS</h6>
            <p>Uw persoonsgegevens kunnen worden gebruikt voor de volgende doeleinden:</p>
            <ul>
              <li>verbetering van onze website;</li>
              <li>marketingdoeleinden;</li>
              <li>social media sharing.</li>
            </ul>

            <h6>COOKIES</h6>
            <p>Om bezoeken aan intervisie.amsterdam te optimaliseren houden wij anonieme bezoekersstatistieken bij. Hiervoor worden cookies gebruikt. Daarnaast worden cookies gebruikt zodat u direct gebruik kunt maken van onze sociale media. U kunt in de instellingen aangeven of u deze cookies wilt toestaan. U hoeft dit slechts eenmalig aan te geven (totdat u uw tijdelijke bestanden wist).</p>

            <h6>WAT ZIJN COOKIES?</h6>
            <p>Cookies zijn kleine tekstbestandjes die op uw computer worden opgeslagen.</p>
            <p>De daarmee verzamelde gegevens zijn niet te herleiden tot een persoon en bevatten geen persoonsgegevens. Wij anonimiseren alle IP-adressen. Er zijn 3 soorten cookies:</p>

            <h6>1. NOODZAKELIJKE COOKIES</h6>
            <p>Dit zijn cookies die nodig zijn voor het goed functioneren van de website. Een voorbeeld is de voorkeur die iemand aangeeft voor het wel of niet accepteren van cookies. Deze cookies worden niet gedeeld met derden.</p>

            <h6>2. ANALYSECOOKIES</h6>
            <p>Deze worden gebruikt om de website en bijbehorende statistieken te analyseren, waardoor wij de website kunnen optimaliseren. Wij gebruiken hiervoor het hulpprogramma Google Analytics. Google kan deze informatie aan derden verschaffen indien Google hiertoe wettelijk wordt verplicht, of voor zover deze derden de informatie namens Google verwerken. Wij hebben hier geen invloed op. Wij hebben Google niet toegestaan de verkregen informatie te delen.</p>

            <h6>3. SOCIAL MEDIA PLUGINS</h6>
            <p>Dit zijn cookies die door derde partijen worden geplaatst, in ons geval door Twitter, LinkedIn en YouTube (Google). Deze cookies maken het mogelijk berichten direct te delen op deze sociale media. Wij hebben geen invloed op hoe Twitter, LinkedIn en YouTube (Google) omgaan met de verkregen informatie. Voor meer informatie verwijzen wij naar de privacy statements van Twitter, LinkedIn en Google.</p>

            <h6>BEWAARTERMIJN</h6>
            <p>Uw persoonsgegevens worden maximaal twee jaar bewaard.</p>

            <h6>UW PERSOONSGEGEVENS EN DERDEN</h6>
            <p>intervisie.amsterdam verstrekt uw persoonsgegevens niet aan derden.</p>

            <h6>DE VEILIGHEID VAN UW PERSOONSGEGEVENS</h6>
            <p>intervisie.amsterdam heeft passende technische en organisatorische maatregelen genomen om uw persoonsgegevens te beschermen tegen verlies of tegen enige vorm van onrechtmatige verwerking. Alle informatie wordt vertrouwelijk behandeld en staat op beveiligde servers.</p>

            <h6>UW RECHTEN</h6>
            <p>U hebt het recht om intervisie.amsterdam te verzoeken u inzage te verlenen in uw persoonsgegevens en/of deze te verbeteren, aan te vullen, te verwijderen of af te schermen. Een dergelijk verzoek kunt u per post richten aan Legal & Fiscal BV, Afdeling Marketing & Communicatie, Postbus 186, 3940 AD  Doorn, of per e-mail aan intervisie@legal.nl.</p>

            <h6>WIJZIGINGEN IN DEZE PRIVACYVERKLARING</h6>
            <p>intervisie.amsterdam behoudt zich het recht voor om deze privacyverklaring op ieder gewenst moment en om welke reden dan ook te wijzigen en adviseert u daarom regelmatig dit gedeelte van de website te controleren.</p>

            <h6>VRAGEN</h6>
            <p>Als u vragen of opmerkingen hebt over de verwerking van uw persoonsgegevens via de website van intervisie.amsterdam, kunt u zich per post richten tot Legal & Fiscal BV, Afdeling Marketing & Communicatie, Postbus 186, 3940 AD  Doorn, of per e-mail aan intervisie@legal.nl. </p>

          </div>
        </div>

        <div className="row">
          <div className="col text-center">
            <Link className="btn btn-primary" to="/">Terug naar de homepage</Link>
          </div>
        </div>
      </div>
    );
  }
}

// connect redux
const mapStateToProps = (state) => ({
  i18n: state.app.i18n
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(Privacy);
