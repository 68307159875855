import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

class Terms extends Component {
  static propTypes = {
    i18n: PropTypes.object.isRequired
  }

  render() {
    const { i18n } = this.props;

    return (
      <div className="container content space">
        <div className="row">
          <div className="col">
            <h2>Algemene voorwaarden</h2>
            <h6>1. TOEPASSELIJKHEID</h6>
            <p>intervisie.amsterdam is een site van Legal & Fiscal BV. Deze algemene voorwaarden zijn van toepassing op iedere rechtsverhouding tussen Legal & Fiscal BV en een gebruiker van de site intervisie.amsterdam (de &ldquo;Site&rdquo;), alsmede op alle rechtsbetrekkingen en (rechts)handelingen die daaruit voortvloeien. </p>

            <h6>2. ACTIVITEITEN</h6>
            <p>intervisie.amsterdam faciliteert een online platform waarop advocaten deelname aan een intervisiebijeenkomst kunnen boeken.</p>

            <h6>3. GEBRUIK VAN DE SITE</h6>
            <p>Advocaten kunnen op geen enkele wijze rechten ontlenen aan de informatie die intervisie.amsterdam verstrekt en publiceert.</p>

            <h6>4. AANSPRAKELIJKHEID</h6>
            <p>intervisie.amsterdam neemt bij de beveiliging van haar gegevens alle zorg in acht die in redelijkheid van haar kan worden verwacht. intervisie.amsterdam is niet aansprakelijk voor verlies van gegevens of onbevoegde toegang tot gegevens die ontstaat ondanks de door intervisie.amsterdam betrachte zorg. </p>

            <h6>5. OVERIGE BEPALINGEN</h6>
            <p>De aan intervisie.amsterdam verstrekte informatie en gegevens zullen vertrouwelijk worden behandeld, indien deze als zodanig worden aangeduid of als intervisie.amsterdam redelijkerwijs wist of kon weten dat deze vertrouwelijk zijn. De aan een intervisiesessie deelnemende advocaten gaan ermee akkoord dat voorafgaand aan de sessie een lijst van deelnemers zal worden verspreid onder de deelnemers.</p>
            <p>intervisie.amsterdam heeft te allen tijde het recht om de Algemene Voorwaarden te wijzigen. De meest actuele versie van de Algemene Voorwaarden is te vinden op de Site.</p>
          </div>
        </div>

        <div className="row">
          <div className="col text-center">
            <Link className="btn btn-primary" to="/">Terug naar de homepage</Link>
          </div>
        </div>
      </div>
    );
  }
}

// connect redux
const mapStateToProps = (state) => ({
  i18n: state.app.i18n
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(Terms);
