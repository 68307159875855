import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import Hero from 'components/Hero';
import Location from 'components/Location';
import Costs from 'components/Costs';
import Program from 'components/Program';
import SocialProof from 'components/SocialProof';
import Agenda from 'components/Agenda';
import Visuals1 from 'components/Visuals1';
import Visuals2 from 'components/Visuals2';

class Homepage extends Component {
  static propTypes = {
    i18n: PropTypes.object.isRequired
  }

  render() {
    const { i18n } = this.props;

    return (
      <div className="homepage">
        <Hero />
        <Visuals1 />
        <Program />
        <Visuals2 />
        <Location />
        <Agenda />
        <Costs />
        <SocialProof />
      </div>
    );
  }
}

// connect redux
const mapStateToProps = (state) => ({
  i18n: state.app.i18n
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(Homepage);
