import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Sessions from 'components/Sessions';

class Agenda extends Component {
  static propTypes = {
    full: PropTypes.bool,

    i18n: PropTypes.object.isRequired
  }

  static defaultProps = {
    full: false
  }

  render() {
    const { full, i18n } = this.props;

    return (
      <div className="gray-bg space">
        <div className="container">
          <div className="row">
            <div className="col">
              <h2>Agenda</h2>
              <p>In 2024 zullen op weekdagen intervisie-sessie plaatsvinden, die doorgaan bij inschrijving door tenminste vijf deelnemers.</p>

              <Sessions full={full} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// connect redux
const mapStateToProps = (state) => ({
  i18n: state.app.i18n
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(Agenda);
