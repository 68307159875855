import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { toast } from 'react-toastify';

// import ContactForm from '@empire/hydra/components/common/ContactForm';
import { ContactForm } from '@hydra/atom/components/common';
import Map from 'components/Map';

import { appSettings } from 'settings';

class Contact extends Component {
  static propTypes = {
    i18n: PropTypes.object.isRequired
  }

  render() {
    const { i18n } = this.props;
    const formUrl = `${appSettings.apiBaseUrl}/contact`;

    return (
      <div className="container space">
        <div className="row">
          <div className="col text-center">
            <h2>Contact</h2>
            <p>Neem contact op met ons als u vragen of opmerkingen heeft!</p>
          </div>
        </div>

        <div className="row pt-5">
          <div className="col-12 col-md-6">
            <Map />
          </div>

          <div className="col-12 col-md-6 map-container">
            <ContactForm
              i18n={i18n}
              postUrl={formUrl}
              onSubmitSuccess={() => toast.success('Dank u wel, uw bericht is successvol verstuurd.')}
              onSubmitFailure={(error) => toast.error(error)}
            />
          </div>
        </div>
      </div>
    );
  }
}

// connect redux
const mapStateToProps = (state) => ({
  i18n: state.app.i18n
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
