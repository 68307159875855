import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Map from 'components/Map';

class Location extends Component {
  static propTypes = {
    i18n: PropTypes.object.isRequired
  }

  render() {
    const { i18n } = this.props;

    return (
      <div className="container">
        <div className="row pt-5">
          <div className="col-12 col-md-6">
            <h2>Locatie</h2>
            <p>Alle sessies vinden plaats in de ontmoetingsruimtes van de Koninklijke Industrieele Groote Club (IGC) op de Dam in het centrum van Amsterdam. IGC verzorgt tevens het diner voor alle deelnemers. De gesprekken vinden plaats in groepen van vijf tot tien deelnemers.</p>
            <p>Voor meer informatie kunt u de website van IGC bezoeken op  <a href="https://igc.nl/">https://igc.nl/</a></p>

            <Link to="/agenda" className="btn btn-primary">Bekijk Agenda</Link>
          </div>

          <div className="col-12 col-md-6 map-container">
            <Map />
          </div>
        </div>
      </div>
    );
  }
}

// connect redux
const mapStateToProps = (state) => ({
  i18n: state.app.i18n
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(Location);
