import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import visual1 from 'images/visual-1.jpg';
import visual2 from 'images/visual-2.jpg';
import visual3 from 'images/visual-3.jpg';

class Visuals1 extends Component {
  static propTypes = {
    i18n: PropTypes.object.isRequired
  }

  render() {
    const { i18n } = this.props;

    return (
      <div className="container-fluid visuals space">
        <div className="row">
          <div className="col-12 col-md-6 text-right">
            <img src={visual1} className="visual-1 img-fluid" alt="" />
            <img src={visual3} className="visual-3 img-fluid" alt="" />
          </div>

          <div className="col-12 col-md-6">
            <img src={visual2} className="visual-2 img-fluid" alt="" />
          </div>
        </div>
      </div>
    );
  }
}

// connect redux
const mapStateToProps = (state) => ({
  i18n: state.app.i18n
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(Visuals1);
