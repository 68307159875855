import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

class Footer extends Component {
  static propTypes = {
    i18n: PropTypes.object.isRequired
  }

  render() {
    const { i18n } = this.props;

    return (
      <div className="footer">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-9">
              <h3>Voor advocaten, door advocaten</h3>

              <p>intervisie.amsterdam is een initiatief van Legal & Fiscal BV, om te voorzien in de behoefte aan intervisie, zoals de Nederlandse Orde van Advocaten in maart 2020 binnen haar kwaliteitstoets verplicht stelde. intervisie.amsterdam is gevestigd te 3941 BW  Doorn aan de Moersbergselaan 17, Postbus 186, 3940 AD  Doorn.</p>

              <strong>Wie zitten er achter dit initiatief?</strong>
              <p>mr. A.P. Macro (beëdigd 1990) en mr. drs. H.J. van der Tak (beëdigd 1993). Bij vragen of opmerkingen kunt u ons het makkelijkst bereiken via <a href="mailto:intervisie@legal.nl">intervisie@legal.nl</a></p>
            </div>

            <div className="col-12 col-md-3">
              <h6>Menu</h6>

              <ul className="list-unstyled">
                <li><Link to="/algemene-voorwaarden">Algemene Voorwaarden</Link></li>
                <li><Link to="/privacy">Privacy</Link></li>
                <li><Link to="/contact">Contact</Link></li>
              </ul>
            </div>
          </div>

          <hr />

          <div className="row">
            <div className="col-12 col-md-8">
              <p className="copyright">© 2021 Legal & Fiscal BV. Alle rechten voorbehouden. intervisie.amsterdam is een handels- en merknaam van Legal & Fiscal BV, KVK 75571072.</p>
            </div>

            <div className="col-12 col-md-4 socials">
              <a href="https://twitter.com/legal__nl" target="_blank" title="Twitter">
                <i className="socicon-twitter" />
              </a>

              <a href="https://www.linkedin.com/company/legalnl" target="_blank" title="LinkedIn">
                <i className="socicon-linkedin" />
              </a>

              <a href="https://www.facebook.com/legal.nl" target="_blank" title="Facebook">
                <i className="socicon-facebook" />
              </a>

              <a href="https://www.instagram.com/legal.nl" target="_blank" title="Instagram">
                <i className="socicon-instagram" />
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// connect redux
const mapStateToProps = (state) => ({
  i18n: state.app.i18n
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
