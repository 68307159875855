import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

class Banner extends Component {
  static propTypes = {
    i18n: PropTypes.object.isRequired
  }

  render() {
    const { i18n } = this.props;

    return (
      <div className="container-fluid banner">
        <div className="row">
          <div className="col text-center">
            <p>U kunt zich nu inschrijven voor 2024. LET OP: Er is een speciale aanbieding voor snelle boekers!</p>

            <Link to="/agenda" className="btn btn-primary">
              Bekijk Agenda
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

// connect redux
const mapStateToProps = (state) => ({
  i18n: state.app.i18n
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(Banner);
