import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

class Costs extends Component {
  static propTypes = {
    i18n: PropTypes.object.isRequired
  }

  render() {
    const { i18n } = this.props;

    return (
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6">
            <h2>PO Punten</h2>
            <p>De NOvA heeft vastgesteld dat elke advocaat in 2024 acht uur aan intervisie dient te besteden. U behaalt met het volgen van onze intervisiebijeenkomst per sessie vier PO-punten. Het certificaat wordt u na betaling en na deelname per mail toegezonden.</p>
            <p>Wij hebben slechts beperkt plaats, dus schrijf u snel in!</p>

            <Link className="btn btn-primary" to="/agenda">Bekijk Agenda</Link>
          </div>
          <div className="col-12 col-md-6">
            <div className="pricing">
              <h6>All-in-one pakket</h6>
              <h4>De kosten</h4>

              <hr />

              <p>Vier uur per sessie</p>
              <ul className="checked-list">
                <li>Inclusief diner</li>
                <li>NOvA erkende gespreksleiders</li>
                <li>Vijf tot tien advocaten per groep</li>
                <li>Kennisdeling en intercollegiale steun</li>
              </ul>

              <div className="price">
                <h3>€ 245,- ex btw voor één sessie</h3>
                <h4>€ 425,- ex btw voor twee sessies</h4>
              </div>

              <p className="text-muted"><em>Advocaten met (voornamelijk) een toevoegingspraktijk betalen € 209,- ex btw voor één sessie en € 364,- ex btw voor twee sessies.</em></p>

              <p className="text-muted"><em>Wanneer u direct twee sessies boekt, waarmee u dus uw acht PO-punten kunt behalen, kan de tweede sessie op een later moment in 2024 gekozen worden.</em></p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// connect redux
const mapStateToProps = (state) => ({
  i18n: state.app.i18n
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(Costs);
