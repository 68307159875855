import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Homepage from 'pages/Homepage';
import Agenda from 'pages/Agenda';
import Kosten from 'pages/Kosten';
import Terms from 'pages/Terms';
import Privacy from 'pages/Privacy';
import Register from 'pages/Register';
import Contact from 'pages/Contact';

export const buildRoutes = () => (
  <Switch>
    <Route path="/" component={Homepage} exact />
    <Route path="/agenda" component={Agenda} exact />
    <Route path="/kosten" component={Kosten} exact />
    <Route path="/algemene-voorwaarden" component={Terms} exact />
    <Route path="/privacy" component={Privacy} exact />
    <Route path="/register" component={Register} exact />
    <Route path="/contact" component={Contact} exact />

    {/* <Route component={NotFound} /> */}
  </Switch>
);
