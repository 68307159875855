const baseUrl = window.__ENV__.BASE_URL;
const sentryUrl = window.__ENV__.SENTRY_URL;

export const appSettings = {
  baseUrl,
  sentryUrl,
  apiBaseUrl: `${baseUrl}/api/v1`,
  authCookieName: '.intervisieLoggedIn',
  languageCookieName: '.intervisieLanguage'
};
