import { createStore, applyMiddleware, combineReducers } from 'redux';

import thunkMiddleware from 'redux-thunk';

import appReducer from './appReducer';

// Here we combine different reducers to be used in this app
const rootReducer = combineReducers({
  app: appReducer
});

export default function configureStore(preloadedState) {
  return createStore(rootReducer, preloadedState,
    applyMiddleware(...[thunkMiddleware])
  );
}
