import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { Carousel } from 'react-responsive-carousel';

class SocialProof extends Component {
  static propTypes = {
    i18n: PropTypes.object.isRequired
  }

  render() {
    const { i18n } = this.props;

    return (
      <div className="gray-bg quote-container">
        <div className="container">
          <div className="row slider-container">
            <div className="col-12">
              <h2>
                Deel uw ervaring
              </h2>
            </div>

            <div className="col-12 col-md-9 m-auto">
              <Carousel
                renderArrowNext={(onClickHandler, hasNext, label) => hasNext && (
                  <button
                    type="button"
                    onClick={onClickHandler}
                    title={label}
                    className="btn-carousel btn-next"
                    >
                    <i className="far fa-chevron-right" />
                    </button>
                )}
                renderArrowPrev={(onClickHandler, hasPrev, label) => hasPrev && (
                <button
                  type="button"
                  onClick={onClickHandler}
                  title={label}
                  className="btn-carousel btn-prev"
                  >
                  <i className="far fa-chevron-left" />
                  </button>
                )}
              >
                <div className="child-slide">
                  <div className="child-slide-content">
                    <h5>Kwaliteit en kennis</h5>
                    <p className="quote">De open sfeer in de sessies van intervisie.amsterdam zorgt voor een bijzonder leerproces en fijne intercollegiale steun.</p>
                    <p className="author">— Isabeau Reinders Folmer</p>
                  </div>
                </div>

                <div className="child-slide">
                  <div className="child-slide-content">
                    <h5 className="mt-5">Structuur en inhoud</h5>
                    <p className="quote">De combinatie van heldere structuur en inhoud met een prettige sfeer kenmerkt de gedegen sessies van intervisie.amsterdam.</p>
                    <p className="author">— Frank Herreveld</p>
                  </div>
                </div>

                <div className="child-slide">
                  <div className="child-slide-content">
                    <h5 className="mt-5">Zinvol en leerzaam</h5>
                    <p className="quote">Bij dezen nogmaals dank voor de zinvolle en leerzame en ook leuke avond, ik heb er echt van genoten. U ziet mij volgend jaar weer, ik kijk ernaar uit.</p>
                    <p className="author">— Hugo Voskamp</p>
                  </div>
                </div>

                <div className="child-slide">
                  <div className="child-slide-content">
                    <h5 className="mt-5">Heldere structuur</h5>
                    <p className="quote">De aanpak en de heldere structuur in de sessie van intervisie.amsterdam onder begeleiding van Axel Macro was een verademing. De interactie en de prettige sfeer zorgden voor een mooie synergie. Een aanrader voor elke professional die zich wil verbeteren in zijn of haar functioneren.</p>
                    <p className="author">— Tuncay Çatak</p>
                  </div>
                </div>

                <div className="child-slide">
                  <div className="child-slide-content">
                    <h5 className="mt-5">Zeer nuttig</h5>
                    <p className="quote">De intervisiesessie voldeed zeker aan mijn verwachtingen. Sterker nog, ik heb er veel aan gehad en nuttige inzichten opgedaan.</p>
                    <p className="author">— Nina B. Hillebrand</p>
                  </div>
                </div>
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// connect redux
const mapStateToProps = (state) => ({
  i18n: state.app.i18n
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(SocialProof);
