import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import Costs from 'components/Costs';
import SocialProof from 'components/SocialProof';

class Kosten extends Component {
  static propTypes = {
    i18n: PropTypes.object.isRequired
  }

  render() {
    const { i18n } = this.props;

    return (
      <div className="homepage kosten">
        <Costs />
        <SocialProof />
      </div>
    );
  }
}

// connect redux
const mapStateToProps = (state) => ({
  i18n: state.app.i18n
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(Kosten);
