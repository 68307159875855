import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';

import { Spacer, AppBar } from '@hydra/atom/components';

import logo from 'images/intervisie-logo.svg';

class TopNav extends Component {
  static propTypes = {
    i18n: PropTypes.object.isRequired
  }

  constructor(props) {
    super(props);

    this.appBar = React.createRef();
  }

  closeNav = () => {
    this.appBar.current.toggleSideNav();
  }

  render() {
    const { i18n } = this.props;

    return (
      <div className="container">
        <div className="row">
          <AppBar ref={this.appBar} transparent onScrollSolid="0">
            <AppBar.Logo>
              <NavLink to="/">
                <img src={logo} className="mr-5 mt-1" alt="logo" />
              </NavLink>
            </AppBar.Logo>

            <AppBar.ItemsHorizontal>
              <Spacer />

              <NavLink to="/agenda">
                Agenda
              </NavLink>

              <NavLink to="/kosten" className="ml-5">
                Kosten
              </NavLink>

              <NavLink to="/contact" className="ml-5">
                Contact
              </NavLink>
            </AppBar.ItemsHorizontal>

            <AppBar.ItemsVertical>
              <NavLink onClick={() => this.closeNav()} to="/">Home</NavLink>
              <NavLink onClick={() => this.closeNav()} to="/agenda">Agenda</NavLink>
              <NavLink onClick={() => this.closeNav()} to="/kosten">Kosten</NavLink>
              <NavLink onClick={() => this.closeNav()} to="/contact">Contact</NavLink>
            </AppBar.ItemsVertical>
          </AppBar>
        </div>
      </div>
    );
  }
}

// connect redux
const mapStateToProps = (state) => ({
  i18n: state.app.i18n
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(TopNav);
