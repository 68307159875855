import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

class Program extends Component {
  static propTypes = {
    i18n: PropTypes.object.isRequired
  }

  render() {
    const { i18n } = this.props;

    return (
      <div className="container">
        <div className="row">
          <div className="col">
            <h2>Programma</h2>
            <p>intervisie.amsterdam zorgt voor een geregistreerde erkende gespreksleider - een advocaat -, een geschikte ontmoetingsruimte en een goed diner, waardoor u in een prettige omgeving de eerste vier intervisiepunten voor het jaar in één avond haalt.</p>
            <p>Iedere deelnemer wordt gevraagd om vooraf een dilemma uit de praktijk door te geven. Aan de hand van een vaste structuur worden één of meerdere dilemma’s besproken.</p>

            <Link to="/agenda" className="btn btn-primary">Bekijk Agenda</Link>
          </div>
        </div>
      </div>
    );
  }
}

// connect redux
const mapStateToProps = (state) => ({
  i18n: state.app.i18n
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(Program);
