import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import visual4 from 'images/visual-4.jpg';
import visual5 from 'images/visual-5.jpg';
import visual6 from 'images/visual-6.jpg';

class Visuals2 extends Component {
  static propTypes = {
    i18n: PropTypes.object.isRequired
  }

  render() {
    const { i18n } = this.props;

    return (
      <div className="container-fluid visuals space">
        <div className="row">
          <div className="col-12 col-md-6 text-right">
            <img src={visual4} className="visual-4 img-fluid" alt="" />
            <img src={visual5} className="visual-5 img-fluid" alt="" />
          </div>

          <div className="col-12 col-md-6 text-right">
            <img src={visual6} className="visual-6 img-fluid" alt="" />
          </div>
        </div>
      </div>
    );
  }
}

// connect redux
const mapStateToProps = (state) => ({
  i18n: state.app.i18n
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(Visuals2);
