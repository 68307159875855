import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import AgendaC from 'components/Agenda';
import Location from 'components/Location';
import SocialProof from 'components/SocialProof';

class Agenda extends Component {
  static propTypes = {
    i18n: PropTypes.object.isRequired
  }

  render() {
    const { i18n } = this.props;

    return (
      <div className="homepage agenda">
        <AgendaC full />
        <Location />
        <SocialProof />
      </div>
    );
  }
}

// connect redux
const mapStateToProps = (state) => ({
  i18n: state.app.i18n
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(Agenda);
