import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { AddressPinMap } from '@hydra/atom/components/common/maps';

class Map extends Component {
  static propTypes = {
    i18n: PropTypes.object.isRequired
  }

  popupContent = () => {
    const { i18n } = this.props;

    return (
      <div className="row p-3">
        <div className="col">
          <p className="display-1">
            <strong>Royal Industrieele Groote Club</strong>
          </p>

          <p className="display-1">
            Dam 27<br />
            1012 JS Amsterdam
          </p>
        </div>
      </div>
    );
  }

  render() {
    const { i18n } = this.props;

    return (
      <AddressPinMap lng={4.893397373015516} lat={52.37278820902247} popupContent={this.popupContent()} />
    );
  }
}

// connect redux
const mapStateToProps = (state) => ({
  i18n: state.app.i18n
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(Map);
