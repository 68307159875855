import React from 'react';
import ReactDOM from 'react-dom';

import * as Sentry from '@sentry/browser';

import { Provider as ReduxProvider } from 'react-redux';

import { appSettings } from './settings';
import App from './App';
import configureStore from './configureStore';

import 'react-toastify/dist/ReactToastify.css';

import './css/app.scss';

if (appSettings.sentryUrl) {
  // Enable sentry if we have a url available
  Sentry.init({ dsn: appSettings.sentryUrl });
}

const store = configureStore();

ReactDOM.render(
  <ReduxProvider store={store}>
    <App />
  </ReduxProvider>,

  document.getElementById('root')
);
